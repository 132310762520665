@use '@angular/material' as mat

@include mat.core()
@include mat.legacy-core()

/* ======== angular material custom palette ======== */
$cd-codicespalette: (50: #e8e8f0, 100: #c5c6d8, 200: #9fa1bf, 300: #797ba5, 400: #5c5e91, 500: #3f427e, 600: #393c76, 700: #31336b, 800: #292b61, 900: #1b1d4e, A100: #8e93ff, A200: #5b62ff, A400: #2831ff, A700: #0e19ff, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #ffffff, A400: #ffffff, A700: #ffffff))
/* ======== angular material custom theme ======== */
$my-custom-primary: mat.define-palette($cd-codicespalette)
$my-custom-accent: mat.define-palette(mat.$teal-palette, 100, 500, A100)
$my-custom-warn: mat.define-palette(mat.$red-palette)
$typo: mat.define-typography-config()

// Light theme
$my-custom-theme: mat.define-light-theme((color: (primary: $my-custom-primary, accent: $my-custom-accent, warning: $my-custom-warn), typopgraphy: $typo))
// Dark theme
// $my-custom-theme: mat-dark-theme($my-custom-primary, $my-custom-accent, $my-custom-warn)

// Main theme defination
@include mat.core-theme($my-custom-theme)
@include mat.all-component-themes($my-custom-theme)
@include mat.all-component-typographies($my-custom-theme)
@include mat.legacy-core-theme($my-custom-theme)
@include mat.all-legacy-component-themes($my-custom-theme)
@include mat.all-legacy-component-typographies()

// Class for snackbar for constitution
.old-version-toast
    margin: 0 !important
    position: absolute
    right: 0px
    top: 66px
    margin-right: 42px !important
    padding: 20px 16px 20px 16px !important
    background: #e61919 !important
    color: white

// Refine alpha index search
.refine-alpha-index-toast
    --mdc-snackbar-container-color: rgba(251, 175, 0)
    --mdc-snackbar-supporting-text-color: #805900

.highlight-span
    background-color: yellow
    scroll-margin-top: 44px

#over
    z-index: 10
    position: relative

#under
    position: relative
    z-index: 1

.note-const-title
    cursor: pointer
    color: #3F427E

.margin-bottom-8
    margin-bottom: 8px

.link-style
    cursor: pointer
    color: #3F427E !important
    text-decoration: underline
