@use '@angular/material' as mat

.global--container
  width: 100%
  padding: 0 32px
  margin: 0 auto
  box-sizing: border-box
  *
    box-sizing: border-box
  .mat-mdc-outlined-button
    --mdc-outlined-button-outline-color: #3F427E

.global--container-small
  width: 100%
  max-width: 840px
  padding: 0 32px
  margin: 0 auto

.global--list-head
  display: flex
  justify-content: space-between
  align-items: center
  padding-top: 32px
  padding-bottom: 24px

.global--list-actions
  display: flex
  align-items: center
  column-gap: 16px
  .mat-mdc-outlined-button.mat-mdc-button-base
    height: 44px
    &.action-btn-icon
      min-width: 44px
      padding: 0
      .action-icon.mat-icon.mat-icon-inline
        font-size: 24px
        margin: 0
        line-height: 44px

.precis-form
  .mat-expansion-panel-header
    padding-left: 0
    padding-right: 8px

  .mat-expansion-panel-body
    padding-left: 0
    padding-right: 0

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true])
    background-color: transparent

  .mat-expansion-panel-header-description
    display: block
    margin-right: 0

.precis-form
  @include mat.icon-button-density(-2)
  @include mat.button-toggle-density(-2)

.precis-keywords-editor
  @include mat.form-field-density(-4)
  .mat-mdc-form-field-subscript-wrapper
    display: none

.precis-form-text
  @include mat.icon-button-density(-2)

.width-100
  width: 100%

.width-50
  width: 50%

.margin-16
  margin: 16px

.margin-right-16
  margin-right: 16px

.margin-left-16
  margin-left: 16px

.margin-top-16
  margin-top: 16px

.margin-bottom-16
  margin-bottom: 16px

.padding-16
  padding: 16px

.sticky-header
  top: 0
  position: fixed
  width: 100%
  z-index: 10
